import $ from 'jquery';
import { bindJQuery } from '@/scripts/helpers/library';
import { addedLazyLoadImages } from '@/scripts/helpers/lazyload';
import {BREAKPOINTS, CLASSES} from '@/scripts/helpers/constants';
import 'slick-carousel/slick/slick';
import video from '../modules/containers/video';
import { accordion as initAccordion } from '../modules/containers/accordion/index';
import { detectBrowser } from '@/scripts/helpers/detectBrowser';

const { IS_OPEN } = CLASSES;
const { DESKTOP, DESKTOP_MD } = BREAKPOINTS;

// legacy accordion
// todo avoid usage
const accordion = $('.js-accordion .accordion-item');
const accordionItemTitle = $('.js-accordion .accordion-item__head');

function openAccordion() {
  const animationTime = 0;
  accordion.not($(this)).removeClass('is-active');
  $(this).toggleClass('is-active');
  accordion
    .not($(this))
    .find('.accordion-item__body')
    .slideUp(animationTime);
  $(this)
    .find('.accordion-item__body')
    .slideToggle(animationTime);
}

accordionItemTitle.on('focus', (e) => {
  accordionItemTitle.removeAttr('style');
});

accordion.on('click', function (e) {
  $(e.target).css('outline', '0');
  openAccordion.call(this);
});

accordion.on('keydown', (e) => {
  if (e.key === 'Enter') {
    openAccordion.call(e.target.parentNode);
  }
});

const showMore = () => {
  if ($('.js-show-more').length) {
    $('.js-show-more').on('click', function () {
      $(this).text($(this).text() === 'Show details' ? 'Hide details' : 'Show details');
      $(this).parent().toggleClass(IS_OPEN);
      const hiddenContent = $(this).parent().prev();
      const innerHeight = hiddenContent.find('.section-content__inner').outerHeight();
      hiddenContent.toggleClass(IS_OPEN);

      if (hiddenContent.hasClass(IS_OPEN)) {
        hiddenContent.css({ height: innerHeight });
      } else {
        hiddenContent.removeAttr('style');
      }
    });
  }
};

const initTrustSlider = () => {
  const trustSldier = $('.js-trust-slider');

  if (trustSldier.length) {
    trustSldier.not('.slick-initialized').slick({
      variableWidth: true,
      slidesToShow: 6,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            autoplay: true,
            variableWidth: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            swipeToSlide: true,
            pauseOnHover: false,
            arrows: false,
            fade: false,
            infinite: true,
            dots: false,
            autoplaySpeed: 0,
            speed: 6000,
            cssEase: 'linear',
            useTransform: false,
          },
        },
      ],
    });
  }
};

const initReviewsSlider = () => {
  const otherFormsSlider = $('.js-reviews-slider');
  const prevArrow = `<button type="button" class="slider-arrow slider-arrow--prev"></button>`;
  const nextArrow = `<button type="button" class="slider-arrow slider-arrow--next"></button>`;

  if (otherFormsSlider.length) {
    otherFormsSlider.not('.slick-initialized').slick({
      infinite: true,
      focusOnSelect: false,
      pauseOnFocus: false,
      arrows: true,
      prevArrow,
      nextArrow,
      cssEase: 'linear',
      variableWidth: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: DESKTOP,
          settings: {
            slidesToShow: 1,
            variableWidth: false,
          },
        },
      ],
    });
  }
};

const initFormsSlider = () => {
  const otherFormsSlider = $('.js-popular-forms-slider');
  const prevArrow = `<button type="button" class="slider-arrow slider-arrow--prev"></button>`;
  const nextArrow = `<button type="button" class="slider-arrow slider-arrow--next"></button>`;

  if (otherFormsSlider.length) {
    otherFormsSlider.not('.slick-initialized').slick({
      infinite: true,
      focusOnSelect: false,
      pauseOnFocus: false,
      arrows: true,
      prevArrow,
      nextArrow,
      cssEase: 'linear',
      slidesToShow: 8,
      slidesToScroll: 1,
      variableWidth: true,
      responsive: [
        {
          breakpoint: DESKTOP_MD,
          settings: {
            slidesToShow: 1,
            arrows: true,
            prevArrow,
            nextArrow,
            variableWidth: true,
          },
        },
      ],
    });
  }
};

const initRatingBlock = () => {
  const rating = $('.reiting');
  const ratingStars = rating.find('.reiting__star');

  const handleHoverStar = function () {
    const index = $(this).index();
    for (let i = 0; i <= index; i += 1) {
      $(ratingStars[i]).addClass('is-active');
    }
  };

  const handleBlurStar = function () {
    ratingStars.removeClass('is-active');
  };

  const handleClickStar = function () {
    const index = $(this).index();
    ratingStars.removeClass('is-half-filled is-filled').addClass('star--empty');

    for (let i = 0; i <= index; i += 1) {
      $(ratingStars[i]).removeClass('star--empty').addClass('is-filled');
    }

    localStorage.setItem('rating-star', index + 1);
  };

  ratingStars.hover(handleHoverStar, handleBlurStar);
  ratingStars.click(handleClickStar);
};

$(document).ready(() => {
  addedLazyLoadImages();
  bindJQuery();
  video();
  initAccordion();
  detectBrowser();
  showMore();
  initRatingBlock();

  function showDetails() {
    $('.js-section-details').toggleClass('is-open');
  }
  $('.js-details-show, .js-details-hide').on('click', showDetails);

  /* MPS-214 */
  initFormsSlider();
  initReviewsSlider();
  initTrustSlider();


  $(window).on( "resize", function() {
    initFormsSlider();
    initReviewsSlider();
    initTrustSlider();
  });
});
