import $ from "jquery";
import lazyframe from "lazyframe";

export default () => {
  let elements = $(".video-player__lazyframe");
  console.log("initets")
  lazyframe(elements, {
    onAppend: iframe => {
      $(iframe).attr("allow", "autoplay");
    }
  });
};
